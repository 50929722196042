import React, { useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import Image from 'next/future/image';
import imageUrlResize from '../../../../../helpers/imageUrlResize';

export interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}

export interface Props {
  images: UIImage[];
  activeIndex: number;
  onClose: () => void;
  onNext: () => void;
  onPrev: () => void;
  onZoom: (e) => void;
  isZoomed: boolean;
  onDotClick: (index: number) => void;
  clickMousePos: { x: number; y: number };
}

export const FullScreenModal = ({
  images,
  activeIndex,
  onClose,
  onNext,
  onPrev,
  onZoom,
  isZoomed,
  onDotClick,
  clickMousePos,
}: Props) => {
  const [cursorPos, setCursorPos] = useState({ x: 0.5, y: 0.5 });

  const handleMouseMove = (e: React.MouseEvent) => {
    const { clientX, clientY, currentTarget } = e;
    const { offsetLeft, offsetTop, clientWidth, clientHeight } = currentTarget as HTMLElement;
    const x = (clientX - offsetLeft) / clientWidth;
    const y = (clientY - offsetTop) / clientHeight;
    setCursorPos({ x, y });
  };

  useEffect(() => {
    setCursorPos(clickMousePos);
  }, [isZoomed]);

  return (
    <div className="modal-fullscreen-image fixed inset-0 z-50 flex flex-wrap items-center justify-center bg-white backdrop-blur-sm">
      <button
        onClick={onClose}
        className="absolute top-0 right-0 z-40 rounded-full p-4 text-gray-500 hover:bg-[#f4f6f6]"
      >
        <XIcon className="h-8 w-8" aria-hidden="true" />
      </button>
      <button onClick={onPrev} className="swiper-button-prev !h-10 !w-10 rounded-full hover:bg-[#f4f6f6]" />
      <button onClick={onNext} className="swiper-button-next !h-10 !w-10 rounded-full hover:bg-[#f4f6f6]" />

      <div className="relative h-[90vh] w-full overflow-hidden" onMouseMove={isZoomed ? handleMouseMove : undefined}>
        <Image
          alt={images[activeIndex].alt}
          src={imageUrlResize(images[activeIndex].src, 'original')}
          loader={({ src }) => src}
          fill
          className={`!relative object-contain [transition:transform_0.25s_ease] ${isZoomed ? 'scale-[2]' : ''}`}
          style={{
            cursor: isZoomed ? 'zoom-out' : 'zoom-in',
            transformOrigin: isZoomed ? `${cursorPos.x * 100}% ${cursorPos.y * 100}%` : 'center center',
          }}
          onClick={onZoom}
        />
      </div>

      <div className="thumbnails bottom-4 flex w-full justify-center overflow-auto">
        {images.map((image, idx) => (
          <div
            key={idx}
            className={`mx-1 h-20 w-20 cursor-pointer border ${idx === activeIndex && 'border-[#0F4A48]'}`}
            onClick={() => onDotClick(idx)}
          >
            <Image
              alt={image.alt}
              src={imageUrlResize(image.src, 'small')}
              loader={({ src }) => src}
              fill
              className={`!relative object-contain [transition:transform_0.25s_ease]`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FullScreenModal;
