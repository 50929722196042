import React, { useState } from 'react';
import { MediaItemWithMods, useNewsletter } from '../../../frontastic';
import Image from '../../../frontastic/lib/image';
import { useFormat } from '../../../helpers/hooks/useFormat';
import Spinner from '../../commercetools-ui/spinner';
import Typography from '../../commercetools-ui/typography';
import CheckCircleIcon from '../../icons/check-circle';
import CheckCrossIcon from '../../icons/check-cross';
import EnvelopeIcon from '../../icons/envelope';
import Markdown from '../content/markdown';
import styles from './newsletter.module.scss';

interface MergeFieldProp {
  label: string;
  value: string;
}

export interface NewsletterProps {
  image?: CustomMediaItem;
  headline?: string;
  disclaimer?: string;
  successHeadline?: string;
  successMessage?: string;
  successHint?: string;
  errorHeadline?: string;
  errorMessage?: string;
  ctaLabel?: string;
  backBtnLabel?: string;
  listId: string;
  mergeFields?: MergeFieldProp[];
  marketingPermissionIds?: string;
}

export interface CustomMediaItem {
  media: MediaItemWithMods;
  title?: string;
}

export default function Newsletter({
  headline,
  image,
  disclaimer,
  successHeadline,
  successMessage,
  successHint,
  errorHeadline,
  errorMessage,
  ctaLabel,
  backBtnLabel,
  listId,
  mergeFields,
  marketingPermissionIds,
}: NewsletterProps) {
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatNewsletterMessage } = useFormat({ name: 'newsletter' });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [userData, setUserData] = useState({ email: '', name: '' });
  const { update } = useNewsletter();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const goBack = () => {
    setSuccess(false);
    setErrorCode(null);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const permissions = marketingPermissionIds.split(';').map((permissionId) => ({
      marketing_permission_id: permissionId.trim(),
      enabled: true,
    }));
    const customMergeFields: Record<string, string> = {};

    for (const field of mergeFields) {
      customMergeFields[field.label] = field.value;
    }

    if (!userData.email?.trim() || userData.email.indexOf('@') <= 0) {
      return alert(formatErrorMessage({ id: 'enter.email', defaultMessage: 'Please enter your email address' }));
    }

    setLoading(true);
    try {
      const result = await update({
        listId,
        customMergeFields,
        userData,
        permissions,
      });

      setSuccess(result.success);
      setErrorCode(result.errorCode ?? null);
    } catch (error) {
      console.error('Subscription error:', error);
      setErrorCode('general-error');
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-wrap items-center gap-x-14 md:flex-nowrap">
      {image && (
        <div className="flex-1 basis-full">
          <Image media={image.media} alt={image?.title} />
        </div>
      )}
      <div className="flex-1 basis-full">
        {errorCode === null && !success && (
          <>
            <div className="mb-8 mt-4 text-2xl font-bold text-neutral-900">{headline}</div>
            <div className="relative">
              {loading && (
                <div className="absolute flex h-full w-full items-stretch justify-center bg-white/50 py-10 px-12">
                  <Spinner />
                </div>
              )}
              <form action="#" className="flex flex-wrap gap-x-4 gap-y-8" onSubmit={onSubmit}>
                <div className="flex w-full flex-wrap gap-8">
                  <div className="flex-auto">
                    <label
                      htmlFor="newsletter-email"
                      className="mb-1 block text-xs font-normal text-neutral-400 dark:text-light-100"
                    >
                      {formatNewsletterMessage({
                        id: 'email.label',
                        defaultMessage: 'Your email address',
                      })}
                    </label>
                    <input
                      id="newsletter-email"
                      name="email"
                      type="email"
                      className="block w-full rounded-lg border-2 border-gray-300 px-4 py-3 focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                      placeholder="E-Mail"
                      onChange={onChange}
                    />
                  </div>
                  <div className="flex-auto">
                    <label
                      htmlFor="newsletter-name"
                      className="mb-1 block text-xs font-normal text-neutral-400 dark:text-light-100"
                    >
                      {formatNewsletterMessage({
                        id: 'name.label',
                        defaultMessage: 'What is your name? (optional)',
                      })}
                    </label>
                    <input
                      id="newsletter-name"
                      name="name"
                      type="text"
                      className="block w-full rounded-lg border-2 border-gray-300 px-4 py-3 focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                      placeholder="Name"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className={styles.wrapper}>
                  <Markdown text={disclaimer} className="font-neutral-900 text-xs font-light" />
                </div>
                <div className="mb-4 w-full shrink-0 md:w-auto">
                  <button
                    type="submit"
                    className=" flex w-full flex-1 items-center justify-center gap-2 rounded-md border border-transparent bg-accent-400 fill-white py-3 px-8 text-base font-medium text-white transition duration-150 ease-out hover:bg-accent-500 focus:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400"
                  >
                    <EnvelopeIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    <Typography>{ctaLabel}</Typography>
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {errorCode !== null && (
          <>
            <div className="mb-8 mt-4 text-2xl font-bold text-neutral-900">
              {errorHeadline ?? 'Ups etwas lief schief...'}
            </div>
            <div className="mb-8 flex items-center gap-3 rounded-lg border-2 border-error-400 bg-error-200 py-2.5 px-4">
              <CheckCrossIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
              <div className="text-sm text-error-400">
                {errorMessage ?? 'Es hat etwas nicht geklappt. Bitte versuche es nochmal.'}
              </div>
            </div>
            <button
              className="flex w-full flex-1 items-center justify-center gap-2 rounded-md border-2 border-neutral-400 bg-neutral-100 py-3 px-8 text-base font-semibold text-neutral-600"
              onClick={goBack}
            >
              <Typography>{backBtnLabel}</Typography>
            </button>
          </>
        )}
        {success && (
          <>
            <div className="mb-8 mt-4 text-2xl font-bold text-neutral-900">{successHeadline ?? 'Almost there!'}</div>
            <div className="mb-8 flex items-center gap-3 rounded-lg border-2 border-blue-600 bg-blue-300 py-2.5 px-4">
              <CheckCircleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
              <div className="text-sm text-blue-600">{successMessage ?? 'Die Email wurde erfolgreich verschickt.'}</div>
            </div>
            <div className={styles.wrapper}>
              <div className="font-neutral-900 text-xs font-light">
                <Markdown
                  text={successHint.replace('%EMAIL%', userData.email)}
                  className="font-neutral-900 text-xs font-light"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
