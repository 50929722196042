import React, { MouseEvent, useEffect, useState } from 'react';
import { Cart } from '@Types/cart/Cart';
import { PaymentLogos } from '@Types/cart/PaymentLogo';
import classnames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import Image from 'components/../frontastic/lib/image';
import Price from 'components/commercetools-ui/price';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import Spinner from '../../../../commercetools-ui/spinner';
import TruckIcon from '../../../../icons/truck';
import { useCheckout } from '../../../checkout/provider';
import { OrderSummaryTotals } from '../../../checkout/types';
import { CartSummaryHandler } from '../../../checkout/utils/CartSummaryHandler';
import DiscountField from '../../../discount/discount-field';

interface Props {
  readonly cart: Cart;
  readonly onSubmit?: (e: MouseEvent) => void;
  readonly submitButtonLabel?: string;
  readonly disableSubmitButton?: boolean;
  readonly showSubmitButton?: boolean;
  readonly showDiscountsForm?: boolean;
  readonly maxVoucherLimit?: number;
  readonly defaultShippingCountry?: string;
  readonly deliveryTime?: string;

  termsLink?: Reference;
  cancellationLink?: Reference;
  privacyLink?: Reference;
  paymentLogos: PaymentLogos[];
}

const CartSummary = ({
  cart,
  onSubmit,
  showSubmitButton = true,
  showDiscountsForm = true,
  submitButtonLabel,
  disableSubmitButton,
  maxVoucherLimit = 1,
  termsLink,
  cancellationLink,
  privacyLink,
  paymentLogos,
  defaultShippingCountry,
  deliveryTime,
}: Props) => {
  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { t } = useTranslation(['checkout']);
  const { calculatingShipping, deliveryCountry } = useCheckout();

  const [cartTotals, setCartTotals] = useState<OrderSummaryTotals>({});

  const submitButtonClassName = `${disableSubmitButton ? 'opacity-75 pointer-events-none' : ''} ${
    !showDiscountsForm ? 'mt-7' : ''
  } w-full rounded-md border border-transparent py-3 px-4 text-base shadow-sm font-medium text-white bg-accent-400 hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 focus:ring-offset-gray-50`;

  const interpolatedComponents = [
    <ReferenceLink key={0} className="cursor-pointer font-medium text-accent-500 hover:underline" target={termsLink} />,
    <ReferenceLink
      key={1}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={cancellationLink}
    />,
    <ReferenceLink
      key={2}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={privacyLink}
    />,
  ];

  const totalTaxes = cart?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0);

  const discountPrice = cart?.lineItems?.reduce((a, b) => {
    return (
      a +
      b.count *
        b.discounts.reduce((x, y) => {
          return x + y.discountedAmount.centAmount;
        }, 0)
    );
  }, 0);

  useEffect(() => {
    const shippingCountry = cart?.shippingAddress?.country || deliveryCountry || defaultShippingCountry;
    setCartTotals(
      calculatingShipping
        ? {}
        : {
            subTotal: CartSummaryHandler.getSubTotal(cart),
            discountTotal: CartSummaryHandler.getDiscountTotal(cart),
            shippingTotal: CartSummaryHandler.getShippingTotal(cart, shippingCountry),
          },
    );
  }, [calculatingShipping, cart?.shippingAddress?.country, deliveryCountry]);

  if (cartTotals === null) {
    return (
      <div className="flex items-stretch justify-center py-20 px-12">
        <Spinner />
      </div>
    );
  }

  return (
    <section
      aria-labelledby="summary-heading"
      className="sm:col-span-8 sm:p-6 lg:col-span-4 lg:mt-0 lg:px-0 lg:pt-0 lg:pb-8"
    >
      {showDiscountsForm && (
        <DiscountField
          name="discountCode"
          maxVoucherLimit={maxVoucherLimit}
          summaryType="pills"
          classNames={{
            section: 'pb-4',
          }}
        />
      )}

      <section
        aria-labelledby="summary-prices"
        className={classnames('pt-1', {
          'mt-6 sm:mt-0': !showDiscountsForm,
          'mt-3': showDiscountsForm,
        })}
      >
        <dl>
          {!cartTotals.shippingTotal ? (
            <div className="flex items-stretch justify-center py-20 px-12">
              <Spinner />
            </div>
          ) : (
            <>
              {cartTotals.subTotal && (
                <div className="flex items-center justify-between border-t border-neutral-400 pt-5 lg:border-none lg:pt-0">
                  <dt className="text-sm text-neutral-900 dark:text-light-100">
                    {formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}
                  </dt>
                  <dd>
                    <Price
                      price={cartTotals.subTotal}
                      className="text-sm font-bold text-neutral-900 dark:text-light-100"
                    />
                  </dd>
                </div>
              )}

              {discountPrice !== 0 && (
                <div className="mt-2.5 flex items-center justify-between">
                  <dt className="flex text-sm text-neutral-900 dark:text-light-100">
                    <span>{formatCartMessage({ id: 'discounts', defaultMessage: 'Discounts' })}</span>
                  </dt>
                  <dd>
                    <Price
                      price={
                        {
                          fractionDigits: cart?.sum?.fractionDigits ?? 2,
                          centAmount: discountPrice == 0 ? 0 : -discountPrice,
                          currencyCode: cart?.sum?.currencyCode,
                        } || {}
                      }
                      className="text-sm font-bold text-neutral-900 dark:text-light-100"
                    />
                  </dd>
                </div>
              )}

              <div className="mt-2.5 flex justify-between">
                <dt className="flex flex-wrap items-center text-sm text-neutral-900 dark:text-light-100">
                  <span>
                    {formatCartMessage({
                      id: 'shipping.estimate.default',
                      values: {
                        country: formatMessage({
                          id: `country.${deliveryCountry || cart.shippingAddress?.country || defaultShippingCountry}`,
                          defaultMessage: defaultShippingCountry ?? 'Germany',
                        }),
                      },
                      defaultMessage: 'Shipping to germany',
                    })}
                  </span>
                  <span className="mt-1 flex basis-full items-center gap-2 text-xs font-light text-primary-300">
                    <TruckIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                    {deliveryTime}
                  </span>
                </dt>
                <dd>
                  <Price
                    price={cartTotals.shippingTotal}
                    className="text-sm font-bold text-neutral-900 dark:text-light-100"
                  />
                </dd>
              </div>
            </>
          )}

          <div className="mt-5 flex justify-between border-t border-neutral-400 pt-5">
            <dt className="text-lg font-bold text-neutral-900 dark:text-light-100">
              {formatCartMessage({ id: 'totalSum', defaultMessage: 'Total sum' })}
              {cart?.taxed ? (
                <div className="text-sm font-light text-neutral-900 dark:text-light-100">
                  {formatCartMessage({
                    id: 'includedVat',
                    defaultMessage: 'Tax included',
                    values: { amount: CurrencyHelpers.formatForCurrency(totalTaxes || {}) },
                  })}
                </div>
              ) : (
                <div className="text-sm font-light text-neutral-900 dark:text-light-100">
                  {formatCartMessage({ id: 'inclVat', defaultMessage: 'Total sum' })}
                </div>
              )}
            </dt>
            <dd>
              <Price
                price={CartSummaryHandler.calculateOrderTotal(cart, cartTotals)}
                className="text-lg font-bold text-neutral-900 dark:text-light-100"
              />
            </dd>
          </div>
        </dl>
      </section>
      {showSubmitButton && (
        <section aria-labelledby="summary-button" className="mt-7">
          <div>
            <button
              type="submit"
              onClick={onSubmit}
              className={classnames(
                'w-full rounded-sm border border-transparent bg-accent-400 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 focus:ring-offset-gray-50',
                {
                  'pointer-events-none opacity-75': disableSubmitButton,
                },
              )}
            >
              {submitButtonLabel || formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' })}
            </button>

            {submitButtonLabel === formatCartMessage({ id: 'ContinueAndPay', defaultMessage: 'Continue and pay' }) && (
              <p className="px-1 py-5 text-center text-xs">
                <Trans i18nKey="disclaimer" t={t} components={interpolatedComponents} />
              </p>
            )}
          </div>
        </section>
      )}
      <div className="mt-6 flex flex-wrap justify-center gap-4">
        {paymentLogos.map((paymentLogo, i) => (
          <div className="w-full flex-1" key={i}>
            <Image media={paymentLogo.logo.media} alt={paymentLogo.logo.title} className="h-full w-full object-cover" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default CartSummary;
