import ProductList from 'components/revelo-ui/products/product-list';

function ProductListWithBannersTastic({ data }) {
  if (!data?.data?.dataSource) return <></>;

  const { items, facets, category, previousCursor, nextCursor, total, totalItems } = data.data.dataSource;
  const visibleFilters = data.visibleFilters ? data.visibleFilters.split(',') : [];
  const debounceDelay = data.debounceDelay ?? 1000;
  const noProductsMarkdown = data.noProductsMarkdown;
  const categoryTitle = data.categoryTitle;
  const banners = data.fieldGroup;

  return (
    <ProductList
      products={items}
      totalProducts={total}
      facets={facets}
      category={category}
      previousCursor={previousCursor}
      nextCursor={nextCursor}
      visibleFilters={visibleFilters}
      noProductsMarkdown={noProductsMarkdown}
      categoryTitle={categoryTitle}
      banners={banners}
      debounceDelay={debounceDelay}
    />
  );
}

export default ProductListWithBannersTastic;
