import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { ShippingMethodMapping } from '@Types/cart/ShippingMethodMapping';
import { Money } from '@Types/product/Money';
import { CurrencyHelpers } from '../../../../helpers/currencyHelpers';
import { OrderSummaryTotals } from '../types';
import { ShippingHandler } from './ShippingHandler';

export class CartSummaryHandler {
  public static getOrderTotals(
    cart: Cart,
    deliveryCountry: string,
    shippingMethods?: ShippingMethod[],
    shippingMethodMap?: ShippingMethodMapping,
    itemShippingMap?: Record<string, string>,
  ): OrderSummaryTotals {
    const itemMap: Record<string, string> = {};

    if (shippingMethods !== undefined && cart.lineItems?.length > 0) {
      for (const lineItem of cart.lineItems) {
        const mapped = CartSummaryHandler.getShippingMethodForItem(
          lineItem,
          deliveryCountry,
          shippingMethods,
          shippingMethodMap,
          itemShippingMap,
        );

        if (lineItem.lineItemId && mapped !== null) {
          itemMap[lineItem.lineItemId] = mapped;
        }
      }
    }

    const orderTotals: OrderSummaryTotals = {
      subTotal: CartSummaryHandler.getSubTotal(cart),
      discountTotal: CartSummaryHandler.getDiscountTotal(cart),
      shippingTotal: CartSummaryHandler.getShippingTotal(cart, deliveryCountry, shippingMethods, itemMap),
    };

    orderTotals.orderTotal = CartSummaryHandler.calculateOrderTotal(cart, orderTotals);

    return orderTotals;
  }

  public static getSubTotal(cart: Cart): Money {
    return (cart.lineItems || []).reduce(
      (total, item) => CurrencyHelpers.addCurrency(total, CurrencyHelpers.multiplyCurrency(item.price, item.count)),
      {
        currencyCode: cart.sum?.currencyCode || 'EUR',
        fractionDigits: cart.sum?.fractionDigits || 2,
        centAmount: 0,
      },
    );
  }

  public static getShippingTotal(
    cart: Cart,
    deliveryCountry: string,
    shippingMethods?: ShippingMethod[],
    itemMap?: object,
  ): Money {
    return (cart.lineItems || []).reduce(
      (total, item) => {
        const shippingCosts = item.shippingDetails?.discountedPrice ?? item.shippingDetails?.price;
        const shippingCountry = item.shippingDetails?.shippingAddress?.country;

        if (shippingCosts && (!shippingCountry || shippingCountry === deliveryCountry)) {
          return CurrencyHelpers.addCurrency(total, shippingCosts);
        }

        // If available, use shipping method for given country (ignoring any discounts / cart calculations)
        if (itemMap?.[item.lineItemId] !== undefined || shippingMethods?.length > 0) {
          const useMethod = shippingMethods?.find((method) => method.shippingMethodId === itemMap[item.lineItemId]);
          const rate = !useMethod ? undefined : ShippingHandler.getShippingRate(useMethod, deliveryCountry)?.price;

          if (rate !== undefined) {
            return CurrencyHelpers.addCurrency(total, rate);
          }
        }

        return total;
      },
      {
        currencyCode: cart.sum?.currencyCode || 'EUR',
        fractionDigits: cart.sum?.fractionDigits || 2,
        centAmount: 0,
      },
    );
  }

  public static getDiscountTotal(cart: Cart): Money {
    return CurrencyHelpers.subtractCurrency(
      {
        currencyCode: cart.sum?.currencyCode || 'EUR',
        fractionDigits: cart.sum?.fractionDigits || 2,
        centAmount: 0,
      },
      CartSummaryHandler.getDiscountTotalWithoutShipping(cart),
    );
  }

  public static calculateOrderTotal(cart: Cart, summary: OrderSummaryTotals): Money {
    const orderTotal = CurrencyHelpers.addCurrency(
      summary.subTotal || CartSummaryHandler.getSubTotal(cart),
      summary.discountTotal || CartSummaryHandler.getDiscountTotal(cart),
    );

    if (summary.shippingTotal) {
      return CurrencyHelpers.addCurrency(orderTotal, summary.shippingTotal);
    }

    return orderTotal;
  }

  public static shippingCostRecalculationRequired(cart: Cart, shippingAddress?: Address): boolean {
    const cartAddress = ShippingHandler.getCartShippingAddress(cart);
    return shippingAddress?.country !== undefined && shippingAddress.country !== cartAddress.country;
  }

  private static getShippingMethodForItem(
    lineItem: LineItem,
    deliveryCountry: string,
    shippingMethods: ShippingMethod[],
    shippingMethodMap: ShippingMethodMapping,
    itemShippingMap?: Record<string, string>,
  ): string | null {
    if (!lineItem.lineItemId) {
      return null;
    }

    if (!!itemShippingMap?.[lineItem.lineItemId]) {
      return itemShippingMap?.[lineItem.lineItemId];
    }

    const method = ShippingHandler.getShippingMethodsForItem(
      lineItem,
      shippingMethods,
      shippingMethodMap,
      deliveryCountry,
    ).find((method) => typeof method !== undefined);

    return method?.shippingMethodId ?? null;
  }

  private static getDiscountTotalWithoutShipping(cart: Cart): Money {
    return CartSummaryHandler.getTargetDiscountTotal(cart, ['lineItems', 'customLineItems', 'totalPrice']);
  }

  private static getTargetDiscountTotal(cart: Cart, targets: string[]): Money {
    return cart.discountCodes?.reduce(
      (total: Money, discount) => {
        const discountAmount =
          discount.discounts?.reduce(
            (amount: number, cartDiscount) =>
              targets.includes(cartDiscount.target) && cartDiscount.discountedAmount?.centAmount
                ? amount + cartDiscount.discountedAmount?.centAmount
                : amount,
            0,
          ) ?? 0;

        return {
          ...total,
          centAmount: total.centAmount + discountAmount,
        };
      },
      {
        currencyCode: cart.sum?.currencyCode || 'EUR',
        fractionDigits: cart.sum?.fractionDigits || 2,
        centAmount: 0,
      },
    );
  }
}
