import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { ShippingMethodMapping } from '@Types/cart/ShippingMethodMapping';
import { ShippingRate } from '@Types/cart/ShippingRate';
import { Attributes } from '@Types/product/Attributes';

export class ShippingHandler {
  private static addressFields: string[] = [
    'firstName',
    'lastName',
    'streetName',
    'streetNumber',
    'additionalStreetInfo',
    //'additionalAddressInfo',
    'company',
    'postalCode',
    'city',
    'country',
    'state',
    'phone',
  ];

  private static readonly TRUE_VALUES = [true, 'true', 'yes', '1', 1];
  private static readonly FALSE_VALUES = [false, 'false', 'no', '0', 0];

  static getCartShippingAddress(cart: Cart): Address | null {
    if (cart.shippingMode === 'Multiple') {
      const shippingAddress =
        cart.lineItems?.find((item) => !!item.shippingDetails?.shippingAddress)?.shippingDetails?.shippingAddress ??
        cart.shippingAddress ??
        null;

      if (shippingAddress?.listId) {
        return {
          ...shippingAddress,
          addressId: shippingAddress.listId,
          listId: undefined,
        };
      }

      return { ...shippingAddress, addressId: undefined };
    }
    return cart.shippingAddress ?? null;
  }

  static isEmptyAddress(address: Address, ignoreList: string[] = []): boolean {
    if (address.addressId && address.addressId.trim().length > 0 && !address.addressId.startsWith('adr-')) {
      return false;
    }

    return ShippingHandler.addressFields.every(
      (field: string) => ignoreList.includes(field) || !address[field] || address[field].trim().length === 0,
    );
  }

  static isSameAddress(address: Address, compare: Address): boolean {
    if (address.addressId) {
      return address.addressId === compare.addressId;
    }

    return ShippingHandler.addressFields.every((field: string) => address[field] === compare[field]);
  }

  static getShippingMethodsForCountry(shippingMethods: ShippingMethod[], country: string): ShippingMethod[] {
    return shippingMethods.filter((shippingMethod) => {
      return !!ShippingHandler.getShippingRate(shippingMethod, country);
    });
  }

  static getShippingRate(shippingMethod: ShippingMethod, country: string): ShippingRate | null {
    return (
      shippingMethod.rates?.find((rate) => {
        return !!rate.locations?.find((location) => location.country === country);
      }) || null
    );
  }

  static getShippingMethodsForItem(
    lineItem: LineItem,
    shippingMethods: ShippingMethod[],
    mapping?: ShippingMethodMapping,
    country?: string,
  ): ShippingMethod[] {
    if (typeof shippingMethods.map !== 'function' || shippingMethods.length === 0) {
      return [];
    }

    if (country) {
      shippingMethods = ShippingHandler.getShippingMethodsForCountry(shippingMethods, country);
    }

    if (!mapping) {
      return shippingMethods;
    }

    const attributesList = lineItem.variant?.attributes || {};
    const filterIds = ShippingHandler.getMappedShippingMethods(mapping, attributesList);
    const mappedMethods = shippingMethods.filter((shippingMethod) =>
      filterIds.includes(shippingMethod.shippingMethodId),
    );

    if (mappedMethods.length > 0) {
      return mappedMethods;
    }

    return shippingMethods.filter((method) => method.shippingMethodId === mapping.defaultShippingMethod);
  }

  private static getMappedShippingMethods(mapping: ShippingMethodMapping, attributes: Attributes): string[] {
    if (!mapping.attributeMapping) {
      return [];
    }

    return mapping.attributeMapping
      .filter((map) => {
        if (!Object.keys(attributes).includes(map.attributeIdentifier)) {
          return false;
        }

        const compareValues = ShippingHandler.getNormalizedValues(attributes[map.attributeIdentifier]);
        let mapValue = map.attributeValue;

        // special case booleans - nekom does not support boolean attribute values so we have to use string
        // representations of boolean values (like 'true', 'yes')
        if (mapValue === true) {
          mapValue = ShippingHandler.TRUE_VALUES;
        } else if (mapValue === false) {
          mapValue = ShippingHandler.FALSE_VALUES;
        }

        return Array.isArray(mapValue)
          ? mapValue.some((val) => compareValues.includes(val))
          : compareValues.includes(mapValue);
      })
      .map((map) => map.shippingMethodIdentifier);
  }

  private static getNormalizedValues(value: any): any[] {
    const normalized = [];

    switch (true) {
      case Array.isArray(value):
        value.forEach((val) => normalized.push(...ShippingHandler.getNormalizedValues(val)));
        break;
      case typeof value === 'string':
        normalized.push(value.trim().toLowerCase());
        break;
      default:
        normalized.push(value);
    }

    return normalized;
  }
}
