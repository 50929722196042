export type CountryOption = {
  display: string;
  data: string;
};

export const countryOptions: CountryOption[] = [
  { display: 'Belgien', data: 'BE' },
  { display: 'Bulgarien', data: 'BG' },
  { display: 'Dänemark', data: 'DK' },
  { display: 'Deutschland', data: 'DE' },
  { display: 'Estland', data: 'EE' },
  { display: 'Finnland', data: 'FI' },
  { display: 'Frankreich', data: 'FR' },
  { display: 'Griechenland', data: 'GR' },
  { display: 'Irland', data: 'IE' },
  { display: 'Italien', data: 'IT' },
  { display: 'Kroatien', data: 'HR' },
  { display: 'Lettland', data: 'LV' },
  { display: 'Litauen', data: 'LT' },
  { display: 'Luxemburg', data: 'LU' },
  { display: 'Malta', data: 'MT' },
  { display: 'Niederlande', data: 'NL' },
  { display: 'Österreich', data: 'AT' },
  { display: 'Polen', data: 'PL' },
  { display: 'Portugal', data: 'PT' },
  { display: 'Rumänien', data: 'RO' },
  { display: 'Schweden', data: 'SE' },
  { display: 'Slowakei', data: 'SK' },
  { display: 'Slowenien', data: 'SI' },
  { display: 'Spanien', data: 'ES' },
  { display: 'Tschechische Republik', data: 'CZ' },
  { display: 'Ungarn', data: 'HU' },
  { display: 'Zypern', data: 'CY' },
];
