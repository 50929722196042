import React, { useEffect, useMemo, useRef } from 'react';
import NextLink from 'next/link';
import { BannerInPLP } from '@Types/content/Banner';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import imageUrlResize from '../../../../helpers/imageUrlResize';
import DataValidator from '../../../../helpers/utils/dataValidator';
import Typography from '../../../commercetools-ui/typography';
import Banner from '../../../revelo-ui/content/banner';

interface Props {
  products: Product[];
  totalProducts: number;
  banners: BannerInPLP[];
  isFirstPage: boolean;
}

const getShowDeviceClasses = (showDevices: 'showAll' | 'showDesktop' | 'showMobile') => {
  switch (showDevices) {
    case 'showDesktop':
      return '!hidden md:!flex';
    case 'showMobile':
      return 'md:!hidden';
    default:
      return '';
  }
};

const getValidBanners = (banners: BannerInPLP[], totalProducts: number, isFirstPage: boolean) => {
  if (!banners || banners.length < 1 || !isFirstPage) return [];

  return banners
    .filter(
      (banner) =>
        banner.maxPosition > 0 && banner.minProducts <= banner.maxPosition && banner.minProducts <= totalProducts,
    )
    .sort((a, b) => a.maxPosition - b.maxPosition)
    .map((banner) => ({
      actualIndex: banner.maxPosition,
      ...banner,
      showDevicesClasses: getShowDeviceClasses(banner.showDevices),
    }));
};

const getVariantAttributeList = ({ attributes }: Variant) => {
  const attributeList = [];

  if (!Array.isArray(attributes)) {
    const { model_year, frame_height_manufacturer, mileage_in_km } = attributes;

    if (model_year?.[0]) attributeList.push(model_year?.[0]);
    if (frame_height_manufacturer?.[0]) attributeList.push(frame_height_manufacturer?.[0]);
    if (mileage_in_km?.[0]) attributeList.push(`${mileage_in_km?.[0]} km`);
  }

  return attributeList;
};

const renderVariantsList = (variants: Variant[], formatMessage) => {
  variants = variants.filter((variant) => variant.availableQuantity > 0);
  if (variants.length === 0) {
    return null;
  }

  let content;

  if (variants.length > 2) {
    content = (
      <>
        {variants[0].attributes?.model_year && <div className="text-xs">{variants[0].attributes?.model_year?.[0]}</div>}
        <div className="bottom-0 text-xs">
          {formatMessage({ id: 'variants.multiple', defaultMessage: 'different sizes & mileage available' })}
        </div>
      </>
    );
  } else {
    content = variants.map((variant, index) => {
      const attributeList = getVariantAttributeList(variant);

      if (attributeList.length === 0) return null;

      return (
        <div
          key={index}
          className="text-xs"
          dangerouslySetInnerHTML={{
            __html: attributeList.join('<span class="px-2">&centerdot;</span>'),
          }}
        />
      );
    });
  }

  return <div className="relative h-12">{content}</div>;
};

const getProductImageSrc = (product: Product, mustFitToUrl = true) => {
  const variant = product.variants.find((variant) => {
    if (!mustFitToUrl) {
      return variant.availableQuantity > 0;
    }

    return variant.availableQuantity > 0 && new RegExp(`/p/${variant.sku}`).test(product._url ?? '');
  });

  let imageUrl =
    !variant && mustFitToUrl
      ? getProductImageSrc(product, false)
      : !!variant?.images?.[0]
      ? variant.images[0]
      : product.variants[0].images?.[0] ?? '';

  imageUrl = imageUrlResize(imageUrl, 'medium');

  return imageUrl;
};

const getProductPrice = (product: Product, formatMessage) => {
  const availableVariants = product.variants.filter(
    (variant) => variant.availableQuantity && variant.price !== undefined,
  );
  const useFromPrice = availableVariants.length > 1;

  const priceVariant = useFromPrice
    ? availableVariants.sort((a, b) => a.price.centAmount - b.price.centAmount)[0]
    : availableVariants.length === 1
    ? availableVariants[0]
    : product.variants[0];

  const recommendedPriceFormatted = CurrencyHelpers.formatForCurrency(priceVariant.recommendedPrice);
  const priceFormatted = CurrencyHelpers.formatForCurrency(priceVariant.price);

  return (
    <div className="mt-3">
      <div className="text-sm font-semibold text-accent-400">
        <Typography>
          {useFromPrice
            ? formatMessage({
                id: 'price.from',
                defaultMessage: 'ab {price}',
                values: { price: priceFormatted },
              })
            : priceFormatted}
        </Typography>
      </div>
      <div className="text-sm text-neutral-400">
        <Typography>
          {formatMessage({
            id: 'price.uvp',
            defaultMessage: 'ehem. UVP {price}',
            values: { price: recommendedPriceFormatted },
          })}
        </Typography>
      </div>
    </div>
  );
};

const List: React.FC<Props> = ({ products, totalProducts, banners, isFirstPage }) => {
  const { formatMessage } = useFormat({ name: 'product' });

  // Memoize valid banners to avoid recalculating
  const validBanners = useMemo(
    () => getValidBanners(banners, totalProducts, isFirstPage),
    [banners, totalProducts, isFirstPage],
  );

  // Ref to store updated banners
  const updatedBannersRef = useRef<BannerInPLP[]>([]);

  useEffect(() => {
    // Start position = first banners initial position
    let previousPositionInLoop = validBanners[0]?.maxPosition ?? 1;
    let countShownBanners = 0;

    updatedBannersRef.current = validBanners.map((banner, i) => {
      if (i > 0 && banner.maxPosition > validBanners[i - 1].maxPosition) {
        countShownBanners++;
        previousPositionInLoop = banner.maxPosition - countShownBanners;
      }

      return { ...banner, actualIndex: previousPositionInLoop };
    });
  }, [validBanners]);

  return (
    <div className="mx-auto max-w-2xl pt-8 lg:max-w-7xl">
      <h2 className="sr-only">{formatMessage({ id: 'products', defaultMessage: 'Products' })}</h2>
      <ul className="grid grid-cols-1 gap-5 align-bottom sm:grid-cols-2 md:grid-cols-3 md:gap-8 lg:grid-cols-3">
        {products.map((product, productIndex) => {
          const renderBannerElements = updatedBannersRef.current
            .filter((banner) => banner.actualIndex === productIndex + 1)
            .map((banner, bannerIndex) => {
              const validBannerLink = DataValidator.isValidReference(banner.bannerLink) ? banner.bannerLink : undefined;

              return (
                <li
                  key={`banner-${bannerIndex}`}
                  className={`PLP-banner ${
                    banner.showDevicesClasses
                  } flex justify-center rounded border border-primary-100 transition-shadow ${
                    validBannerLink && 'hover:shadow-xl'
                  } overflow-hidden`}
                  data-position={banner.maxPosition}
                  data-actual-index={banner.actualIndex}
                >
                  <Banner
                    backgroundImage={banner.backgroundImage}
                    backgroundImageFit="contain"
                    backgroundColor={banner.backgroundColor}
                    bannerText={banner.bannerText}
                    textColor={banner.textColor}
                    buttonLabel={banner.buttonLabel}
                    buttonStyle={banner.buttonStyle}
                    buttonLink={banner.buttonLink}
                    bannerLink={banner.bannerLink}
                    customClassName="min-h-[280px]"
                  />
                </li>
              );
            });

          return (
            <React.Fragment key={product.productId}>
              {renderBannerElements}
              <li
                key={`product-${product.productId}`}
                className="PLP-product flex justify-center self-end rounded border border-primary-100 px-5 py-2.5 transition-shadow hover:shadow-xl"
              >
                <NextLink href={product._url || ''}>
                  <a className="group max-w-full">
                    <div className="relative w-full">
                      <Image src={getProductImageSrc(product, true) || ''} alt={product.name} layout="fill" />
                    </div>
                    <div className="mt-4 text-sm">{product.variants[0].attributes?.brand?.[0]}</div>
                    <h3
                      className="w-full overflow-hidden truncate text-base font-semibold text-gray-700 dark:text-light-100"
                      title={product.variants[0].attributes?.model_name?.[0]}
                    >
                      {product.variants[0].attributes?.model_name?.[0]}
                    </h3>
                    {renderVariantsList(product.variants, formatMessage)}
                    {getProductPrice(product, formatMessage)}
                  </a>
                </NextLink>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default List;
