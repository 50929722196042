import React, { useEffect } from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';

interface Props {
  readonly onChange: (propName: string, newValue: string) => void;
  readonly value: string;
  readonly name: string;
  readonly type?: string;
  readonly autoComplete?: string;
  readonly isDisabled?: boolean;
  readonly containerClassNames?: string;
  readonly classNames?: string;
  readonly validation?: RegisterOptions;
  readonly placeholder?: string;
}

const InputElement = ({
  onChange,
  value,
  name,
  type,
  autoComplete,
  isDisabled,
  containerClassNames,
  classNames,
  validation,
  placeholder,
}: Props) => {
  const { register, control } = useFormContext();

  const fieldValue = useWatch({
    control,
    name: name,
  });

  useEffect(() => {
    onChange(name, fieldValue);
  }, [fieldValue]);

  return (
    <div className={containerClassNames ? containerClassNames : 'mt-2'}>
      <input
        type={type ?? 'text'}
        id={name}
        name={name}
        disabled={isDisabled}
        autoComplete={autoComplete}
        placeholder={placeholder}
        className={
          classNames ||
          'block w-full rounded-lg border-2 border-gray-300 px-4 py-3 shadow focus:border-primary-400 focus:ring-primary-400 sm:text-sm'
        }
        defaultValue={value}
        {...register(name, validation)}
      />
    </div>
  );
};

export default InputElement;
