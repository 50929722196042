import { useState } from 'react';
import { Discount } from '@Types/cart/Discount';
import { useCart } from '../../../../frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { DiscountStatus } from '../../types/DiscountStatus';
import DiscountInput from './discount-input';
import DiscountSummary from './discount-summary';
import DiscountFieldProps from './interfaces/DiscountFieldProps';
import { useCheckout } from '../../checkout/provider';

const DiscountField = (props: DiscountFieldProps) => {
  const { formatMessage } = useFormat({ name: 'cart' });
  const { redeemDiscountCode, removeDiscountCode, data: cart } = useCart();
  const { setRecalculationRequired } = useCheckout();

  const [isSubmitting, setSubmitting] = useState(false);
  const [discountCode, setDiscountCode] = useState(props.discountCode ?? '');
  const [statusMessage, setStatusMessage] = useState('');

  const maxVouchers = Math.max(props.maxVoucherLimit ?? 0);
  const showInput = maxVouchers === 0 || !cart?.discountCodes || cart.discountCodes.length < maxVouchers;
  const showSummary =
    props.summaryType && props.summaryType !== 'none' && cart?.discountCodes && cart.discountCodes.length > 0;

  const handleApply = (): void => {
    setSubmitting(true);
    setStatusMessage(undefined);

    redeemDiscountCode(discountCode)
      .then((response: DiscountStatus) => {
        if (response.code === 'error') {
          setStatusMessage(
            formatMessage({
              id: `cart.discount.${response.message[0].toLowerCase()}${response.message.substring(1)}`,
              defaultMessage: formatMessage({
                id: 'cart.discount.error',
                values: { error: response.message },
                defaultMessage: response.message,
              }),
            }),
          );
        } else {
          setRecalculationRequired(true);
        }
      })
      .catch((error) => {
        setStatusMessage(
          formatMessage({
            id: 'cart.discount.error',
            defaultMessage: 'Failed to process discount code - {error}',
            values: {
              error: error.message,
            },
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleRemove = async (discount: Discount): Promise<DiscountStatus | void> => {
    const status = await removeDiscountCode(discount);
    setRecalculationRequired(true);
    return status;
  };

  return (
    <section
      aria-labelledby="discount-field"
      className={props.classNames?.section || 'mt-7 lg:mt-0 lg:border-t lg:border-gray-200 lg:p-6'}
    >
      {showInput && (
        <form onSubmit={(e) => e.preventDefault()} noValidate>
          <DiscountInput
            name={props.name}
            formId={props.formId}
            discountCode={discountCode}
            label={props.label ?? formatMessage({ id: 'cart.discount.input', defaultMessage: 'Discount code' })}
            buttonLabel={props.buttonLabel ?? formatMessage({ id: 'cart.discount.apply', defaultMessage: 'Apply' })}
            handleApply={handleApply}
            handleCodeChange={setDiscountCode}
            isProcessing={isSubmitting}
            statusMessage={statusMessage}
            classNames={props.classNames?.input}
          />
        </form>
      )}
      {showSummary && (
        <DiscountSummary
          discounts={cart?.discountCodes ?? []}
          handleRemove={handleRemove}
          summaryType={props.summaryType}
          classNames={props.classNames?.summary}
        />
      )}
    </section>
  );
};

export default DiscountField;
