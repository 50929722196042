import { useEffect, useState } from 'react';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import FormFieldRadioGroup from '../../../forms/form-field-radiogroup';
import { useCheckout } from '../../provider';
import { PaymentDescription } from '../index';

type Props = {
  preselected: string;
  descriptions: PaymentDescription[];
};

const PaymentMethods = ({ preselected, descriptions }: Props) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { paymentMethods, selectedPaymentMethod, fetchingPaymentMethods, setSelectedPaymentMethod } = useCheckout();
  const [paymentMethodOptions, setPaymentMethodOptions] = useState(null);

  const getPaymentMethodSubline = () => {
    if (paymentMethodOptions === null) {
      return '';
    }

    if (paymentMethods.length === 0) {
      return formatCheckoutMessage({
        id: 'paymentMethods.failed',
        defaultMessage: 'Communication error, please retry',
      });
    }

    return formatCheckoutMessage({
      id: 'askPaymentPreference',
      defaultMessage: 'What do you prefer to pay with?',
    });
  };

  useEffect(() => {
    setPaymentMethodOptions(
      paymentMethods?.map((paymentMethod) => ({
        id: paymentMethod.paymentMethodId,
        value: paymentMethod.paymentMethodId,
        label: paymentMethod.name || paymentMethod.description,
        image: paymentMethod.image,
        default: selectedPaymentMethod
          ? selectedPaymentMethod === paymentMethod.paymentMethodId
          : preselected === paymentMethod.paymentMethodId
          ? preselected
          : false,
        description: descriptions.find((item: PaymentDescription) => item.paymentId === paymentMethod.paymentMethodId)
          ?.description,
      })) ?? [],
    );
  }, [paymentMethods, selectedPaymentMethod]);

  return (
    <FormFieldRadioGroup
      name="paymentMethod"
      label={formatCheckoutMessage({ id: 'payment', defaultMessage: 'Payment' })}
      smallLabel={getPaymentMethodSubline()}
      options={paymentMethodOptions || []}
      containerClassNames="col-span-full pt-6"
      labelClassNames="personal-information-header col-span-full text-xl font-bold"
      smallLabelClassNames="block font-normal text-sm leading-5 text-gray-500 pt-1"
      onChange={(val) => val && setSelectedPaymentMethod(val)}
      validation={{ required: true }}
      waitingForOptions={fetchingPaymentMethods}
    />
  );
};

export default PaymentMethods;
