import { EmptyState } from 'components/commercetools-ui/empty-state';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { NextFrontasticImage } from 'frontastic/lib/image';
import { isValidFrontasticImage } from '../../utils/ImageUtils';
import CartErrorComponent from '../cart-error';
import { CartError } from '../errors/CartError';

interface Props {
  pageTitle?: string;
  image?: NextFrontasticImage;
  title?: string;
  subtitle?: string;
  ctaLabel?: string;
  ctaLink?: Reference;
  cartErrors?: CartError[];
}

const EmptyCart = ({ pageTitle, image, title, subtitle, ctaLabel, ctaLink, cartErrors }: Props) => {
  //i18n messages
  const { formatMessage } = useFormat({ name: 'cart' });

  if (!isValidFrontasticImage(image)) {
    image = undefined;
  }

  return (
    <>
      <EmptyState
        pageTitle={formatMessage({ id: 'cart.shopping', defaultMessage: pageTitle })}
        title={title}
        subtitle={formatMessage({ id: 'cart.empty', defaultMessage: subtitle })}
        callToAction={formatMessage({ id: 'cart.start.shopping', defaultMessage: ctaLabel })}
        callToActionLink={ctaLink}
        image={image}
      />

      {cartErrors && cartErrors.length > 0 && (
        <div>
          {cartErrors.map((error, index) => (
            <CartErrorComponent key={`cart-error-${index}`} error={error} />
          ))}
        </div>
      )}
    </>
  );
};

export default EmptyCart;
