import React, { FC } from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/solid';
import { TermFacet } from '@Types/result/TermFacet';
import TermFilter from '../../../commercetools-ui/term-filter';
import Typography from '../../../commercetools-ui/typography';

type TermFilterWrapperProps = {
  facet: TermFacet;
  disabled?: boolean;
  key?: string;
};

const TermFilterDisclosure: FC<TermFilterWrapperProps> = ({ facet, disabled = false }) => {
  if (!facet?.terms?.length) return <></>;

  return (
    <div className="pb-6">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between py-1 text-gray-400 hover:text-gray-500">
              <span className="text-left font-medium text-neutral-600 dark:text-light-100">
                <Typography>{facet.label}</Typography>
              </span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
            <Disclosure.Panel className="pt-6">
              <TermFilter facet={facet} disabled={disabled} />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default TermFilterDisclosure;
