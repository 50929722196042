import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { HiArrowLeft as PrevIcon, HiArrowRight as NextIcon } from 'react-icons/hi';
import { useFormat } from '../../../helpers/hooks/useFormat';
import { updateURLParams } from '../../../helpers/utils/updateURLParams';
import IconButton from '../content/iconbutton';

type Props = {
  previousCursor?: string;
  nextCursor?: string;
  navClassNames?: string;
};

const Pagination: React.FC<Props> = ({ previousCursor, nextCursor, navClassNames }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();

  const [previousPageURL, setPreviousPageURL] = useState<string>('/');
  const [nextPageURL, setNextPageURL] = useState<string>('/');

  useEffect(() => {
    if (previousCursor) {
      setPreviousPageURL(updateURLParams([{ key: 'cursor', value: previousCursor }], true, ['cursor']));
    }

    if (nextCursor) {
      setNextPageURL(updateURLParams([{ key: 'cursor', value: nextCursor }], true, ['cursor']));
    }
  }, [previousCursor, nextCursor, router.query]);

  return (
    <nav className={classnames(`flex items-center justify-between py-3`, navClassNames)} aria-label="Pagination">
      <div className="flex flex-1  justify-between gap-x-3">
        {!previousCursor ? (
          <div className="min-w-[125px]" />
        ) : (
          <NextLink href={previousPageURL}>
            <a aria-label="Previous Page">
              <IconButton
                type="hollow"
                text={formatMessage({ id: 'prev', defaultMessage: 'Previous' })}
                className="min-w-[125px]"
                icon={<PrevIcon size={20} />}
                iconPosition="left"
              />
            </a>
          </NextLink>
        )}
        {!nextCursor ? (
          <div className="min-w-[125px]" />
        ) : (
          <NextLink href={nextPageURL}>
            <a aria-label="Next Page">
              <IconButton
                type="hollow"
                text={formatMessage({ id: 'next', defaultMessage: 'Next' })}
                className="min-w-[125px]"
                icon={<NextIcon size={20} />}
                iconPosition="right"
              />
            </a>
          </NextLink>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
