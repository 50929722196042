import { FC, useCallback, useMemo } from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/solid';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { RangeFacet } from '@Types/result/RangeFacet';
import PriceRange from 'components/commercetools-ui/range-filter';
import { useFormat } from 'helpers/hooks/useFormat';
import { URLParam } from 'helpers/utils/updateURLParams';

type PriceFilterDisclosureProps = {
  facets: Facet[];
  products: Product[];
  disabled?: boolean;
  updatePriceFilteringParams: (params: URLParam[], shouldSubmit: boolean) => void;
};

const PriceFilterDisclosure: FC<PriceFilterDisclosureProps> = ({
  products,
  facets,
  disabled = false,
  updatePriceFilteringParams,
}) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const facet = useMemo(() => facets?.find((facet) => facet.identifier === 'variants.price') as RangeFacet, [facets]);

  const handleChange = useCallback(
    (values: [number, number], shouldSubmit = true) => {
      const params = [
        { key: 'facets[variants.price][min]', value: values[0].toString() },
        { key: 'facets[variants.price][max]', value: values[1].toString() },
      ];

      updatePriceFilteringParams(params, shouldSubmit);
    },
    [updatePriceFilteringParams],
  );

  return (
    <div className="pb-6">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between py-1 text-gray-400 hover:text-gray-500">
              <span className="font-medium text-neutral-600 dark:text-light-100">
                {formatMessage({ id: 'price', defaultMessage: 'Price' })}
              </span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
            <Disclosure.Panel className="pt-6">
              <PriceRange
                facet={facet}
                currency={products?.[0]?.variants?.[0]?.price?.currencyCode}
                disabled={disabled}
                onChange={handleChange}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default PriceFilterDisclosure;
