import React, { useCallback, useEffect, useRef } from 'react';
import { Term } from '@Types/result/Term';
import { TermFacet } from '@Types/result/TermFacet';
import { useFilter } from '../../../frontastic';
import { FilterUtils } from '../../revelo-ui/utils/FilterUtils';

export type TermFilterParams = {
  index: number;
  value: string;
};

type TermFilterDisclosureProps = {
  facet: TermFacet;
  disabled?: boolean;
};

type TermItemProps = {
  term: Term;
  index: number;
  handleChange: (index: number, checked: boolean) => void;
  selected: Record<string, boolean>;
  disabled?: boolean;
};

const TermItem = ({ term, index, handleChange, selected, disabled = false }: TermItemProps) => {
  const checkbox = useRef<HTMLInputElement>(null);
  const handleClick = useCallback(() => {
    checkbox.current?.click();
  }, []);

  return (
    <div className="relative flex items-start" key={term.identifier}>
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          className="h-5 w-5 cursor-pointer rounded border-gray-300 text-transparent"
          disabled={disabled}
          onChange={(e) => handleChange(index, e.target.checked)}
          checked={selected[term.key] ?? false}
          ref={checkbox}
        />
      </div>
      <div className="ml-3 text-sm" onClick={handleClick}>
        <label htmlFor="comments" className="cursor-pointer font-medium text-gray-700 dark:text-light-100">
          {term.label}
        </label>
      </div>
    </div>
  );
};

const TermFilter: React.FC<TermFilterDisclosureProps> = ({ facet, disabled = false }) => {
  const { filter, setFilter } = useFilter();
  const { params, selected } = filter.terms[facet.key] ?? { params: [], selected: {} };

  useEffect(() => {
    FilterUtils.sortFacetTerms(facet);
    setFilter((filter) => {
      const selected = Object.fromEntries(facet?.terms?.map((term) => [term.key, term.selected]));
      const params = [];

      if (facet?.terms?.length) {
        for (const [index, term] of facet.terms.entries()) {
          if (term.selected) {
            params.push({ index, value: term.key, selected: true });
          }
        }
      }

      return {
        ...filter,
        terms: {
          ...filter.terms,
          [facet.key]: { params, selected },
        },
      };
    });
  }, [facet]);

  const handleChange = useCallback(
    (index: number, checked: boolean) => {
      if (!facet?.terms) return;

      let newParams = [...params];

      if (!checked) newParams = newParams.filter((param) => param.index !== index);
      else newParams = [...newParams, { index, value: facet.terms[index].key }];

      setFilter((filter) => ({
        ...filter,
        terms: {
          ...filter.terms,
          [facet.key]: {
            params: newParams,
            selected: { ...selected, [facet.terms[index].key]: checked },
          },
        },
      }));
    },
    [facet, params, selected],
  );

  return (
    <div className="-m-1 flex max-h-[182px] flex-col flex-nowrap gap-2 overflow-y-auto p-1">
      {facet.terms.map((term, index) => (
        <TermItem
          term={term}
          index={index}
          handleChange={handleChange}
          selected={selected}
          key={index}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default TermFilter;
